$thunder: url('../assets/brand/th1.svg');
$thunder-lt: url('../assets/brand/th1-lt.svg');

:root {
  --chevron-color: red; /* Define a CSS variable with a default value */
}

// fix for chrome form autocompletion

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px $gray-800 inset !important;
  -webkit-text-fill-color: $gray-500;
  font-size: 16px;
}
// divider betwen icon and input field
.input-group-text {
  border-right: 2px solid $gray-500;
}

//styling for newsNotification
.news-notification {
  border-radius: 4px;
  background-color: $secondary;
  border-left: 3px solid $primary;
  padding: 1rem;
  a {
    width: 30px;
    height: 30px;
    border-radius: 4px;
    border: 2px solid $primary;
  }
}

//styling for simplebar scrollbar
.simplebar-track {
  background-color: $medium-emphasis !important;

  width: 2px !important;
  border-radius: 1px !important;

  margin-right: 5px;
}

.simplebar-scrollbar:before {
  background-color: $primary !important;
  color: $primary !important;
  top: 0px !important;
  bottom: 0px !important;
  left: 0px !important;
  right: 0px !important;
  //   opacity: 0.9 !important;
}

.simplebar-visible:before {
  opacity: 0.9 !important;
}

//styling for tables navigation (sorting, pagination)
.navigation-icons {
  fill: $primary;
}

//styling to hide input navigation for numbers
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  // appearance: none;
  // margin: 0;
}

/* Remove the default arrows */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  // -webkit-appearance: none;
  // appearance: none;
}

//custom sidebar
.sidebar .nav-link.active {
  border-radius: 0 5px 5px 0;
  border-width: 0 3px 0 0;
  border-color: $white;
  background-image: $thunder;
  background-repeat: no-repeat;
  background-position: center right 10%;
  background-size: contain;
}

//custom navigation for tables

.pagination {
  .page-item:first-child {
    border-left: 2px solid white;
    border-radius: 5px 0 0 5px;
    a {
      background-color: $blue-dark-300;
    }
  }
  .page-item:last-child {
    border-right: 2px solid white;
    border-radius: 0 5px 5px 0;
    a {
      background-color: $blue-dark-300 !important;
    }
  }
  .disabled {
    opacity: 0.5;
  }
}

.table-striped th,
td {
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
}

.thunderBg {
  background-color: $secondary;
  background-image: $thunder;
  background-repeat: no-repeat;
  background-position: right 15% center;
  background-size: contain;
}

// //for react select component
// .react-select-container {
//   // padding-left: 1rem !important;

//   &:focus {
//     // border: 2px solid red;
//     // border-color: $primary;
//   }
// }

// .sorting-marker {
//   opacity: 1;
//   color: red;
//   fill: red;

//   & > * {
//     opacity: 1;
//     color: red;
//     fill: red;
//   }
// }

//for disabled forms
.form-control:disabled,
.form-select:disabled,
.react-select__control--is-disabled,
.form-control[disabled] {
  background-color: #212538 !important;
  color: #545454 !important;
  border-color: #545454 !important;
}

//for custom accordion
.accordion-button {
  flex-direction: row-reverse !important;
  margin-right: auto;
  width: unset;
}

.note-container {
  &:nth-child(even) {
    background-color: #434b5e;
  }
  span {
    font-size: 10px;
  }
}

.label-white {
  label {
    color: white;
  }
}

table .opacity-25 {
  opacity: 0.5 !important;
}

//for disabling up down arrows on input field
.arrowsDisabled {
  input::-webkit-inner-spin-button,
  input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

.custom-fill-bar {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  &:before {
    display: inline-block;
    background-color: var(--cui-gray-dark);
    position: absolute;
    opacity: 0.2;
    min-width: 100%;
    min-height: 100%;
    content: '';
  }
  .animated {
    background-image: linear-gradient(
      45deg,
      rgba(255, 255, 255, 0.15) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.15) 50%,
      rgba(255, 255, 255, 0.15) 75%,
      transparent 75%,
      transparent
    );
    background-size: 15px 15px;
    animation: 1s linear infinite stripes;
  }
  @keyframes stripes {
    0% {
      background-position-x: 15px;
    }
  }
}

.store-value {
  .card-body {
    display: flex;
    justify-content: flex-end;

    .fs-6 {
      font-size: x-large !important;
      color: $primary;
    }
    .bg-none {
      color: $primary !important;
    }
  }
}

//for light theme ------------------------------------

.light-theme {
  //pagination
  .page-item {
    &,
    &:first-child a,
    &:last-child a {
      background-color: #f0f4f7 !important;
    }

    &.active {
      background-color: $secondary !important;
      span {
        background-color: $secondary !important;
      }
    }
    a.page-link {
      color: black;
    }
  }

  //count on page
  .form-select {
    background-image: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'><path fill='none' stroke='#{rgb(135,0,244)}' stroke-linecap='round' stroke-linejoin='round' stroke-width='4' d='M2 5l6 6 6-6'/></svg>");
  }

  //dropdown
  .react-select__control,
  .react-select__menu,
  .form-control {
    background-color: #f0f4f7;
  }
  .react-select__indicator {
    color: $secondary;
  }

  //inpouts
  //other
  .bg-gradient {
    // color: red;
    background-image: radial-gradient(circle at 130% 80%, #f5f5f5 0, #ffffff 100%) !important;
  }

  .card {
    box-shadow: none;
  }

  .sidebar .nav-link.active {
    background-image: $thunder-lt;
  }

  //styling for newsNotification
  .news-notification {
    background-color: #252f3c;
    color: #fff;
  }

  .thunderBg {
    background-color: #252f3c;
    background-image: $thunder-lt;
    color: #fff;
  }

  //styling for tables navigation (sorting, pagination)
  .navigation-icons {
    fill: #252f3c;
  }
}
