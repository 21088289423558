@import '@coreui/coreui/scss/functions';
@import '@coreui/coreui/scss/variables';
@import '@coreui/coreui/scss/maps';
@import '@coreui/coreui/scss/mixins';
@import '@coreui/coreui/scss/utilities';

$theme-name: 'light-theme' !default;
$theme-prefix: 'light' !default;

.#{$theme-name} {
  @import 'light/variables';
  @import 'light/utilities';
  @import 'light/root';

  @include theme($theme-map);

  // Alert variants
  @each $state, $value in $alert-variants-dt {
    $alert-background: map-get($value, 'alert-bg');
    $alert-border: map-get($value, 'alert-border');
    $alert-color: map-get($value, 'alert-color');

    @if (contrast-ratio($alert-background, $alert-color) < $min-contrast-ratio) {
      $alert-color: mix(
        map-get($value, 'base-color'),
        color-contrast($alert-background),
        abs($alert-color-scale)
      );
    }
    .alert-#{$state} {
      @include alert-variant($alert-background, $alert-border, $alert-color);
    }
  }

  // Button variants
  @each $state, $variant in $button-variants-dt {
    .btn-#{$state} {
      @include button-variant($variant);
    }
  }

  @each $state, $variant in $button-outline-ghost-variants-dt {
    .btn-outline-#{$state} {
      @include button-outline-variant($variant);
    }
  }

  @each $state, $variant in $button-outline-ghost-variants-dt {
    .btn-ghost-#{$state} {
      @include button-ghost-variant($variant);
    }
  }

  // List group variants
  @each $state, $variant in $list-group-variants-dt {
    .list-group-item-#{$state} {
      @include list-group-item-variant($state, $variant);
    }
  }

  // Fix Firefox option color
  @-moz-document url-prefix("") {
    select:not([multiple]) option,
    select optgroup {
      color: initial;
    }
  }

  // Utilities
  @import '@coreui/coreui/scss/utilities/api';
}
